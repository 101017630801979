import {
  ModalHasAppliedTalentBank,
  ModalStatusError,
  ModalStatusSuccess,
  ModalTalentBankApplied,
} from 'components/atoms';
import { IModalStatus } from 'components/molecules/TalentBank/types';
import { createContext, useEffect, useState, useMemo } from 'react';
import { apiApplicantsV3, apiV2 } from 'services/api';
import useStore from 'store';
import cookies, { redirect } from 'utils/cookies';

export interface IApplyTalentBankContextProps {
  hasApplied: boolean;
  onApply(): void;
}

export interface IPropsChildren {
  children: React.ReactElement;
}

const ApplyTalentBankContext = createContext({} as IApplyTalentBankContextProps);

function ApplyTalentBankProvider({ children }: IPropsChildren) {
  const {
    session: { isAuth, loading, user },
    company: { company, loading: loadingCompany, statuses },
    featuresToggle: { isEnabled },
  } = useStore();
  const [hasApplied, setHasApplied] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [showModalByStatus, setShowModalByStatus] = useState<IModalStatus>({
    success: false,
    has_applied: false,
    error: false,
    update: false,
  });
  const textModalSuccess = `Seu cadastro no banco de talentos da ${company?.name} foi realizado com sucesso!
  Entraremos em contato caso surja uma nova oportunidade compatível com as suas competências.`;

  const handleApplyingTalentBank = async (applied: boolean) => {
    try {
      const hasApplyingTalentBank = JSON.parse(cookies.get('applying-talent-base') || null);
      if (
        statuses?.talentBank?.status !== 'has_applied' &&
        !applied &&
        hasApplyingTalentBank &&
        hasApplyingTalentBank.applied &&
        hasApplyingTalentBank.slug === company?.slug
      ) {
        setFirstLoad(true);

        const apiAplicants = isEnabled('applicantsV3') ? apiApplicantsV3 : apiV2;
        await apiAplicants.post('/applicant/apply-job', {
          referenceId: company.referenceId,
          slug: company.slug,
        });

        setShowModalByStatus({
          success: true,
          has_applied: false,
          error: false,
          update: false,
        });
      } else {
        const statusesModal = {
          success: false,
          has_applied: false,
          error: false,
          update: false,
        };
        statusesModal[statuses?.talentBank?.status] = true;
        !loading && setShowModalByStatus(statusesModal);
      }
      cookies.set(
        'applying-talent-base',
        JSON.stringify({ applyingTalentBase: false, applied: false, slug: '', referenceId: '' }),
        { expires: null }
      );
    } catch (err) {
      if (err?.response?.status === 401) {
        cookies.set(
          'applying-talent-base',
          JSON.stringify({
            applyingTalentBase: true,
            applied: false,
            slug: company.slug,
            referenceId: company.referenceId,
          }),
          { expires: null }
        );
        return redirect(`${process.env.CANDIDATE_HOST_V3}/profiler`);
      }
      if (err?.response?.status === 400) return redirect(`${process.env.CANDIDATE_HOST_V3}/curriculo`);
    }
  };

  useEffect(() => {
    cookies.set(window.name, JSON.stringify({ applyingTalentBase: false }), { expires: null });
    const applied = user?.companies?.applications?.includes(company?.referenceId);
    setHasApplied(!!applied);

    if (!firstLoad && !loadingCompany && !loading && company) handleApplyingTalentBank(applied);
  }, [company, loading, loadingCompany]);

  function setCookiesApplyTalentBank() {
    const tabKey = cookies.get('redirectCurrentWindow') || '';
    const tab = JSON.parse(cookies.get(tabKey) || '{}');
    const payload = {
      ...tab,
      company: {
        referenceId: company.referenceId,
        slug: company.slug,
        name: company.name,
      },
      applyingTalentBase: true,
      origin: !isAuth ? 'login' : '',
    };

    cookies.set(window.name, JSON.stringify(payload), { expires: null });
    cookies.set(
      'applying-talent-base',
      JSON.stringify({
        applyingTalentBase: true,
        applied: false,
        slug: company.slug,
        referenceId: company.referenceId,
      }),
      { expires: null }
    );
  }

  const handleApply = async () => {
    if (!isAuth) {
      setCookiesApplyTalentBank();
      return redirect(process.env.AUTH_HOST);
    }

    setShowModalByStatus({
      success: false,
      has_applied: false,
      error: false,
      update: true,
    });
  };

  const onRefreshTalentBank = async () => {
    setCookiesApplyTalentBank();
    return redirect(`${process.env.CANDIDATE_HOST_V3}/curriculo`);
  };

  const handleSendTalentBank = async () => {
    if (firstLoad) return;

    try {
      setFirstLoad(true);
      const apiAplicants = isEnabled('applicantsV3') ? apiApplicantsV3 : apiV2;
      await apiAplicants.post('/applicant/apply-job', {
        referenceId: company.referenceId,
        slug: company.slug,
      });

      cookies.set(window.name, JSON.stringify({ applyingTalentBase: false }), { expires: null });
      cookies.set(
        'applying-talent-base',
        JSON.stringify({ applyingTalentBase: false, applied: false, slug: '', referenceId: '' }),
        { expires: null }
      );
      setShowModalByStatus({
        success: true,
        has_applied: false,
        error: false,
        update: false,
      });
    } catch (err) {
      if (err?.response?.status === 401) {
        cookies.set(
          'applying-talent-base',
          JSON.stringify({
            applyingTalentBase: true,
            applied: false,
            slug: company.slug,
            referenceId: company.referenceId,
          }),
          { expires: null }
        );
        return redirect(`${process.env.CANDIDATE_HOST_V3}/profiler`);
      }
      if (err?.response?.status === 400) return redirect(`${process.env.CANDIDATE_HOST_V3}/curriculo`);
    }
  };

  const handleSuccessAccept = () => {
    setShowModalByStatus({
      success: false,
      has_applied: false,
      error: false,
      update: false,
    });
    setHasApplied(true);
  };

  const handleCloseModalTalentBankApplied = () => {
    setShowModalByStatus({
      success: false,
      has_applied: false,
      error: false,
      update: false,
    });
  };

  const handleErrorAccept = () => {
    setShowModalByStatus({
      success: false,
      has_applied: false,
      error: false,
      update: false,
    });
    setHasApplied(false);
  };

  const values = useMemo(() => ({ hasApplied, onApply: handleApply }), [hasApplied, handleApply]);

  return (
    <>
      <ModalTalentBankApplied
        onClose={handleCloseModalTalentBankApplied}
        show={showModalByStatus.update}
        onRefresh={onRefreshTalentBank}
        onSend={handleSendTalentBank}
      />
      <ModalHasAppliedTalentBank
        show={showModalByStatus.has_applied && hasApplied}
        onClose={handleSuccessAccept}
      />
      <ModalStatusSuccess
        subText={textModalSuccess}
        show={showModalByStatus.success}
        onClose={handleSuccessAccept}
        typeModal="talentbank"
      />
      <ModalStatusError typeModal="talentbank" show={showModalByStatus.error} onClose={handleErrorAccept} />

      <ApplyTalentBankContext.Provider value={values}>{children}</ApplyTalentBankContext.Provider>
    </>
  );
}

export { ApplyTalentBankProvider, ApplyTalentBankContext };
