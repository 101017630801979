import { Badge, Icon, Text } from '@solides/management-util-ui';
import { useRouter } from 'next/router';
import { useSWRConfig } from 'swr';

import {
  Button,
  ModalNotAceptableCandidacy,
  ModalReviewCurriculum,
  ModalStatusError,
  ModalStatusSuccess,
  ModalUpdateCurriculum,
} from 'components/atoms';
import { Vacancy, VacancyType } from 'types/vacancy';

import useStore from 'store/index';
import { useEffect, useState } from 'react';
import { applyVacancyInJobs } from 'utils/vacancy';
import { handleToAuth } from 'utils/user';
import { CandidacyProvider } from 'contexts';
import cookies, { redirect as cookieRedirect } from 'utils/cookies';
import { api, getApiApplicants } from 'services/api';
import { getCookies } from 'cookies-next';
import { DOMAIN_COOKIE } from 'utils/constants';
import { useCompany } from 'hooks';
import { ModalHasProfiler } from 'components/atoms/ModalHasProfiler';
import { ModalDuplicatedAcount } from 'components/atoms/ModalDuplicatedAcount';
import { ModalPCD } from 'components/atoms/ModalPCD';
import { ModalRecommendation } from 'components/atoms/ModalRecommendation';

interface IHeaderVacancyPage {
  vacancy: Vacancy;
  applications: number[];
}

interface IRecommendation {
  name: string;
  email: string;
}

export const HeaderVacancyPage = ({ vacancy, applications }: IHeaderVacancyPage) => {
  const router = useRouter();
  const { mutate } = useSWRConfig();
  const { isAuth, user } = useStore(store => store.session);
  const { isEnabled } = useStore(store => store.featuresToggle);
  const { isLoadingCompany } = useCompany();

  const origin_router = cookies.get('origin_router') || '';

  const isValidUser = !user?.email || !user?.name || !user?.cpf;
  const isAppliedVacancy = applications?.includes(vacancy?.id);

  const [showModalByStatus, setShowModalByStatus] = useState({
    review: false,
    review_processing: false,
    has_want_to_apply: false,
    has_applied: isAppliedVacancy,
    has_profiler: true,
    success: false,
    error: false,
    update: false,
  });

  const [loadingEnabledToApply, setLoadingEnabledToApply] = useState<boolean>(false);

  const [modalNotAceptableCandidacy, setModalNotAceptableCandidacy] = useState<boolean>(false);
  const [modalPCD, setModalPCD] = useState<boolean>(false);
  const [candidacyMessage, setCandidacyMessage] = useState<string>();
  const [candidacyTitle, setCandidacyTitle] = useState<string>();
  const [showModalDuplicated, setShowModalDuplicated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const isClosed = vacancy?.currentState !== 'em_andamento';
  const receivingResume = vacancy?.receivingResume;
  const textModalSuccess = `Candidatura realizada com sucesso na vaga ${vacancy?.title}. Você também receberá um email com mais detalhes.`;

  const isInternalVacancy = vacancy?.type === VacancyType.internal;
  const redirect = window?.location.href;
  const source = router?.query?.origem;
  const isApplicantsV3Enabled = useStore(store => store.featuresToggle.isEnabled('applicantsV3'));

  const handleConfirmOrReviewApplyVacancy = async () => {
    applyVacancyInJobs({ vacancy, redirect, source });
  };

  const applyVacancyInJobsWithRecommendation = async ({
    recommendation,
  }: {
    recommendation: IRecommendation;
  }) => {
    applyVacancyInJobs({ vacancy, redirect, source, recommendation });
  };

  const applyVacancyInJobsWithProfiler = async () => {
    applyVacancyInJobs({ vacancy, redirect, source, goToProfiler: true });
  };

  const checkVacancyModals = async () => {
    let hasReqFields = false;
    try {
      const vacancyHasRequiredFields =
        !!vacancy?.requiredFields?.length || !!vacancy?.language?.length || !!vacancy?.hardSkills?.length;

      if (vacancyHasRequiredFields) {
        setLoadingEnabledToApply(true);

        const { data: requiredFields } = await api.get(
          `/home/vacancy/enabledtoapply?id=${vacancy?.id}&email=${user?.email}`
        );

        hasReqFields = requiredFields?.length > 0;

        const isShowReviewModal =
          isAuth && origin_router !== '/' && !hasReqFields && !isValidUser && !isAppliedVacancy;

        setShowModalByStatus(state => ({ ...state, review: isShowReviewModal }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEnabledToApply(false);
    }

    if (isValidUser) {
      handleConfirmOrReviewApplyVacancy();
    } else if (hasReqFields) {
      setShowModalByStatus(state => ({ ...state, update: true }));
    } else {
      setShowModalByStatus(state => ({
        ...state,
        review: true,
        has_want_to_apply: true,
      }));
    }
  };

  const checkIsInternalEmployee = async () => {
    const jtoken = getCookies()?.token;

    try {
      setIsLoading(true);
      const apiAplicants = getApiApplicants(isApplicantsV3Enabled);
      const { data } = await apiAplicants.get(`applicant/check-employee/${vacancy?.id}`, {
        headers: {
          jtoken,
        },
      });

      const openInternalVacancyModal = !data?.isActiveEmployee;

      if (openInternalVacancyModal) {
        setCandidacyTitle('Candidatura Interna');
        setCandidacyMessage(
          'Esta vaga foi planejada apenas para os colaboradores desta empresa. Caso você seja um colaborador, entre em contato com o seu RH.'
        );
        setModalNotAceptableCandidacy(true);
      } else {
        if (!isAppliedVacancy) {
          checkVacancyModals();
        }
      }
    } catch (error) {
      const redirect = window.location.href;
      const source = router.query?.origem;
      applyVacancyInJobs({ vacancy, redirect, source });
    } finally {
      setIsLoading(false);
    }
  };

  const handleMyApplications = () => {
    return cookieRedirect(`${process.env.CANDIDATE_HOST}/#vacancy`);
  };

  const handleSendApplyVacancy = async (recommendations: IRecommendation | null = null) => {
    const jtoken = getCookies()?.token;

    const data = {
      vagaId: vacancy?.id,
      source: router.query?.origem || 'jobs',
      recommendations,
    };

    try {
      if (!showModalByStatus.review_processing) {
        setShowModalByStatus(state => ({ ...state, review_processing: true }));
        setIsLoading(true);
        const apiAplicants = getApiApplicants(isApplicantsV3Enabled);
        const response = await apiAplicants.post('applicant/apply-job', data);

        const isError = response?.data?.message === 'Falha ao enviar o teste profiler.';
        if (isError) {
          throw new Error('Falha ao enviar o teste profiler.');
        }

        mutate('/applicant/my-applications');
        setShowModalByStatus(state => ({ ...state, success: true }));
        setShowModalByStatus(state => ({ ...state, review: false }));
        setShowModalByStatus(state => ({ ...state, review_processing: false }));
      } else {
        console.log('Loading actual request...');
      }
    } catch (error) {
      console.error(error.message || JSON.stringify(error));
      const hasApplied = error.response?.data?.message === 'Você já se aplicou anteriormente para esta vaga.';
      const isNotAceptableVacancy = error?.response?.status === 406;
      const hasNoProfiler = error?.response?.status === 401;
      const hasDuplicated = error?.response?.status === 409;
      const pcdOnly =
        error?.response?.status === 400 &&
        error?.response?.data?.message?.includes('Cadastro incompleto para esta vaga.') &&
        vacancy?.pcdOnly;

      if (hasDuplicated) {
        setShowModalDuplicated(true);
      } else if (hasApplied) {
        setShowModalByStatus(state => ({ ...state, has_applied: !state.has_applied }));
      } else if (isNotAceptableVacancy) {
        setModalNotAceptableCandidacy(true);
        setCandidacyMessage(error?.response?.data?.message);

        let titleNotAceptable = '';
        if (error?.response?.data?.message?.includes('não')) {
          titleNotAceptable = 'Candidatura Externa';
        } else {
          titleNotAceptable = 'Candidatura Interna';
        }
        setCandidacyTitle(titleNotAceptable);
      } else if (hasNoProfiler) {
        const splitMessage = error?.response?.data?.message.split('.');
        setCandidacyTitle(splitMessage[0]);
        setCandidacyMessage(error?.response?.data?.message);
        setShowModalByStatus(state => ({
          ...state,
          review: false,
          has_profiler: false,
        }));
      } else if (pcdOnly) {
        setModalPCD(true);
      } else {
        setShowModalByStatus(state => ({ ...state, error: true }));
      }
      setShowModalByStatus(state => ({
        ...state,
        review: false,
        review_processing: !state.review_processing,
      }));
      return error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleApplyVacancyNoAuth = () => {
    cookies.set('want_to_apply', 'true', { expires: null });
    handleToAuth();
  };

  const handleApplyVacancyIsAuth = () => {
    if (isInternalVacancy) {
      return checkIsInternalEmployee();
    }
    checkVacancyModals();
  };

  const handleApplyVacancy = () => {
    !isAuth ? handleApplyVacancyNoAuth() : handleApplyVacancyIsAuth();
  };

  const goBack = () => {
    if (window?.history?.state?.idx > 0) {
      router.back();
    } else {
      router.push('/');
    }
  };

  const handleQuickApply = () => {
    setShowModalByStatus(state => ({ ...state, review: false }));
  };

  useEffect(() => {
    const hasWantToApply = !!cookies.get('want_to_apply');
    const hasSignUpNewUser = !!cookies.get('sign_up_new_user');

    if (hasWantToApply && hasSignUpNewUser) {
      cookies.remove('sign_up_new_user');
      handleConfirmOrReviewApplyVacancy();
    } else if (hasWantToApply) {
      if (!isAppliedVacancy && !!applications) {
        handleApplyVacancy();
      }
      cookies.remove('want_to_apply', DOMAIN_COOKIE);
    }
  }, []);

  return (
    <>
      {!modalNotAceptableCandidacy &&
        showModalByStatus.review &&
        !showModalByStatus.has_applied &&
        showModalByStatus.has_want_to_apply && (
          <>
            <ModalReviewCurriculum
              show={!isEnabled('hasRecommendation')}
              onClose={() => setShowModalByStatus(state => ({ ...state, review: false }))}
              onReview={handleConfirmOrReviewApplyVacancy}
              onSend={handleQuickApply}
              isLoading={isLoading}
            />

            <ModalRecommendation
              show={isEnabled('hasRecommendation')}
              onClose={() => setShowModalByStatus(state => ({ ...state, review: false }))}
              onReview={applyVacancyInJobsWithRecommendation}
              onConfirm={handleSendApplyVacancy}
              vacancyId={vacancy.id}
            />
          </>
        )}
      <ModalNotAceptableCandidacy
        show={modalNotAceptableCandidacy}
        onClose={() => setModalNotAceptableCandidacy(false)}
        candidacyMessage={candidacyMessage}
        candidacyTitle={candidacyTitle}
      />
      <ModalHasProfiler
        show={!showModalByStatus.has_profiler}
        onClose={() => setShowModalByStatus(state => ({ ...state, has_profiler: true }))}
        candidacyMessage={candidacyMessage}
        candidacyTitle={candidacyTitle}
        onSend={applyVacancyInJobsWithProfiler}
      />
      <ModalUpdateCurriculum
        show={showModalByStatus.update}
        onClose={() => setShowModalByStatus(state => ({ ...state, update: false }))}
        onConfirm={handleConfirmOrReviewApplyVacancy}
      />
      <ModalStatusSuccess
        show={showModalByStatus.success}
        onMyApplication={handleMyApplications}
        onClose={() => setShowModalByStatus(state => ({ ...state, success: !state.success }))}
        subText={textModalSuccess}
        typeModal="vacancy"
      />
      <ModalStatusError
        typeModal="vacancy"
        show={showModalByStatus.error}
        onClose={() => setShowModalByStatus(state => ({ ...state, error: !state.error }))}
      />
      <ModalDuplicatedAcount show={showModalDuplicated} onClose={() => setShowModalDuplicated(false)} />
      <ModalPCD show={modalPCD} onClose={() => setModalPCD(false)} />

      <CandidacyProvider vacancy={vacancy}>
        <section className="flex justify-center p-4 bg-white sticky top-0 left-0 shadow-sm">
          <div className="flex flex-col lg:flex-row items-stretch lg:items-center gap-y-2 lg:gap-y-0 gap-x-0 lg:gap-x-4 max-w-6xl w-full">
            <div className="flex-1 flex:col justify-between items-center gap-x-2 sm:gap-x-3 sm:flex">
              <div className="flex">
                {!vacancy?.isHiddenJob && !isClosed && (
                  <button
                    type="button"
                    onClick={goBack}
                    className="text-gray-700"
                    title="Voltar para a página anterior"
                    aria-label="Voltar para a página anterior"
                  >
                    <Icon face="arrow_back_ios" />
                  </button>
                )}
                <div className="flex flex-col items-start mr-auto">
                  <h1 className="text-subtitle1 font-semibold">{vacancy?.title}</h1>
                  <Text variant="subtitle2" className="text-gray-500">
                    {vacancy?.companyName}
                  </Text>
                  {isAppliedVacancy && !isClosed && (
                    <Badge color="success" variant="ghost" data-cy="already-applied-header">
                      Você já se candidatou para esta vaga
                    </Badge>
                  )}
                </div>
              </div>
              {!isAppliedVacancy && !isClosed && !!receivingResume && (
                <div className="flex flex-col sm:flex-row gap-y-2 sm:gap-y-0 gap-x-0 sm:gap-x-4">
                  <Button
                    text="Quero me candidatar"
                    isLoading={isLoadingCompany || loadingEnabledToApply}
                    disabled={isLoadingCompany || isClosed || loadingEnabledToApply}
                    data-cy="candidacy-button"
                    className="lg:w-[242px] min-w-[193px] px-5 py-3 text-[14px]"
                    onClick={handleApplyVacancy}
                  />
                </div>
              )}
            </div>
          </div>
        </section>
      </CandidacyProvider>
    </>
  );
};
